import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '../../../../styles/themes/vad/constants';
import {
  fontFamilyEN as fontFamily,
  fontSize,
} from '../../../../styles/themes/vad/font';

const useStyles = makeStyles(theme => ({
  heroVideoBannerWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    color: '#FFFF',
    backgroundColor: theme.palette.common.defaultBgGrey,
    height: 'calc(var(--vh-static, 1vh) * 100) !important',
    minHeight: '635px !important',
    // height: '100vh',

    '& .heading': {
      fontSize: fontSize.s72Rem,
      fontWeight: 700,
      ...theme.mixins.marginLeft(0),
      [theme.breakpoints.down('md')]: {
        fontSize: fontSize.s40Rem,
        lineHeight: fontSize.s48Rem,
      },
      '& .richText': {
        overflow: 'hidden',
        textOverflow: 'clip',
        display: '-webkit-box',
        '-webkit-line-clamp': '3',
        '-webkit-box-orient': 'vertical',
        [theme.breakpoints.down('lg')]: {
          '-webkit-line-clamp': '4',
        },
        [theme.breakpoints.down('md')]: {
          '-webkit-line-clamp': '2',
        },
        [theme.breakpoints.down('sm')]: {
          '-webkit-line-clamp': '4',
        },
      }
    },

    '& .bannerDescriptionText': {
      [theme.breakpoints.down('md')]: {
        marginTop: pxToRem(16),
        fontSize: fontSize.s16Rem,
      },
      marginTop: pxToRem(24),
      fontSize: fontSize.s22Rem,
      '& .richText': {
        '& a': {
          color: theme.palette.common.white,
        },
      },
    },

    '& .videoBannerContentWrapper': {
      position: 'relative',
      height: 'inherit',
      display: 'flex',
      flexDirection: 'column',
      zIndex: '2',
      justifyContent: 'center',
      width: '49.3%',

      [theme.breakpoints.down('md')]: {
        width: ' 93.6%',
        justifyContent: 'flex-end',
        paddingBottom: '100px',
        height: '100%'
      },

      '& .videoBannerBtn': {
        display: 'inline-block',
        '& .videoBannerCTA': {
          fontWeight: 700,
          backgroundColor: theme.palette.common.SeaGreen,
          marginTop: pxToRem(40),

          '& .MuiButton-endIcon': {
            ...theme.mixins.marginLeft(16),
          },
          '& .btn-txt > p, & .btn-txt > span': {
            display: 'block',
            margin: '0',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '20ch',
          },
        },
      },
    },


    '& .scrollBtnSection': {
      position: 'absolute',
      bottom: '3.6%',
      left: '50%',
      zIndex: 2,
      transform: 'translate(-50%, 0%)',
      margin: 0,
      [theme.breakpoints.down('md')]: {
        ...theme.mixins.paddingLeft(0),
      },

      '& .scrollBtn': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: pxToRem(12),
        fontWeight: 700,

        '& .scrollText': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: '20ch',
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        },

        '& .MuiBox-root': {
          width: pxToRem(36),
          height: pxToRem(36),
          border: '1px solid white',
          '& svg': {
            width: pxToRem(8),
            height: pxToRem(14),
          },
        },

        '& .arrowBtn': {
          position: 'unset',
          cursor: 'pointer',
        },
      },
    },

    '& .bannerBackground': {
      position: 'absolute',
      // height: 'inherit',
      // height: '100vh',
      height: 'calc(var(--vh-static, 1vh) * 100)',
      minHeight: '635px !important',
      width: '100%',

      '&:before': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        height: '100%',
        width: '100%',
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(32, 32, 32, 0.30) 42.71%, rgba(69, 69, 69, 0.10) 100%, rgba(69, 69, 69, 0.08) 100%)',
        zIndex: 1,
      },

      '& .bgImage': {
        top: '0',
        left: '0',
      },

      '& video': {
        objectFit: 'cover',
      },
    },
    '& .heroSvg': {
      position: 'absolute',
      width: 'auto',
      height: 'calc(var(--vh-static, 1vh) * 83.1)',
      bottom: '16px',
      ...theme.mixins.right('-150px'),
      zIndex: 2,
      '& img': {
        width: 'auto',

        [theme.breakpoints.down('md')]: {
          width: '100%',
        },
      },

      [theme.breakpoints.down('md')]: {
        // height: 'calc(var(--vh-static, 1vh) * 100) ',
        height: '113%',
        bottom: 'unset',
        top: '43%',
        ...theme.mixins.left('-89px'),
        ...theme.mixins.right(0),
      },
    },
  },
}));

export default useStyles;
